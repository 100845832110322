import React from "react"
import AffiliationSlider from "../affiliation-slider/AffiliationSlider"
import BlogFooter from "../blog/BlogFooter"
import CTABanner from "../cta-banner/CTABanner"
import FormBuilder from "../forms/FormBuilder"

const GlobalFooterItem = ({ blocks, phone, meta, isLandingPage = false }) => {
  const {
    cta_heading,
    description,
    background_image,
    button,
    logo_slider_heading,
    logo_slider,
    form_heading,
    form_sub_heading,
    blog_banner_heading,
    form,
  } = blocks

  const getCtaBanner = () => {
    if (cta_heading) {
      return (
        <CTABanner
          background={
            background_image.value.length > 0
              ? background_image.value[0].fluid
              : null
          }
          bgDescription={
            background_image?.value?.length > 0
              ? background_image?.value[0].description
              : ""
          }
          heading={cta_heading.value}
          description={description.value}
          buttons={button}
          phone={phone.value}
          meta={meta}
        />
      )
    }
  }
  const getFormBanner = () => {
    if (form && !isLandingPage) {
      return (
        <section className="form-banner-container" id="form-banner">
          <div className="container">
            <FormBuilder
              heading={form_heading}
              subHeading={form_sub_heading}
              form={form}
              formGridStyle={"form-grid-3"}
              phone={phone.value}
              block={false}
            />
          </div>
        </section>
      )
    }
  }

  const getAffiliationBanner = () => {
    if (logo_slider_heading) {
      return (
        <AffiliationSlider
          heading={logo_slider_heading.value}
          images={logo_slider}
        />
      )
    }
  }

  const getBlogBanner = () => {
    if (blog_banner_heading) {
      return (
        <div className="container">
          {" "}
          <BlogFooter heading={blog_banner_heading.value} />{" "}
        </div>
      )
    }
  }
  return (
    <div>
      {getCtaBanner()}
      {getFormBanner()}
      {getAffiliationBanner()}
      {getBlogBanner()}
    </div>
  )
}

export default GlobalFooterItem
