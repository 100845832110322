import React from "react"
import ReviewImage from "./ReviewImage"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { shuffle } from "../../assets/js/helper"
import Quote from '../../assets/images/quote-left.png'

const StaticReview = ({ staticReviews, reviewImages }) => {
  const getRating = rating => {
    switch (rating.value[0].codename) {
      case "n1_star":
        return "★"
      case "n2_stars":
        return "★★"
      case "n3_stars":
        return "★★★"
      case "n4_stars":
        return "★★★★"
      case "n5_stars":
        return "★★★★★"
      default:
        break
    }
  }

  const { date, rating, review, reviewer } = shuffle(
    staticReviews.value
  ).elements

  return (
    <>
      <div className="singleReview_container">
        <div className="singleReview_info">
          <h2>Customer Review</h2>
          <div className='divider'>
              <div className='quote'>
                <img src={Quote} alt='quote'/>
              </div>
            </div>
          <div className="review_quote">
            <RichTextElement value={review.value} />
          </div>
          <div className="review_details">
            <div>{reviewer.value}</div>
            {date?.value && <span>|</span>}
            <div>{date.value}</div>
          </div>
          <div>
            <div className="review_stars">{getRating(rating)}</div>
          </div>
        </div>
        <ReviewImage image={shuffle(reviewImages.value)} />
      </div>
    </>
  )
}

export default StaticReview
